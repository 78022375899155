import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '0, 77, 64',
		'primary-dark': '0, 37, 26',
		'accent': '0, 200, 83',
		'accent-plus': '255, 255, 255',
	},
});
